import { debounce } from "./globals";

let winWidth = window.innerWidth;

const didWidthChange = debounce(() => {
  if (winWidth !== window.innerWidth) {
    winWidth = window.innerWidth;
    matchingHeightsAction();
  }
}, 100);

const tallestEl = domGroups => {
  const heights = domGroups.map(el => {
    el.style.height = "";
    return el.clientHeight;
  });

  return Math.max.apply(null, heights);
};

const hide = el => {
  el.setAttribute("data-display", getComputedStyle(el).display);
  el.style.display = "none";
};

const show = el => {
  el.style.display = el.dataset.display;
};

const matchingHeightsAction = () => {
  const domEls = [
    ...document.querySelectorAll("[data-matching-heights-control]")
  ];

  domEls.forEach(domEl => {
    const disableAt = domEl.dataset.matchingHeightsControl || 0;

    const domGroups = [
      ...domEl.querySelectorAll("[data-matching-heights-target]")
    ];
    const domIgnore = [
      ...domEl.querySelectorAll("[data-matching-heights-ignore]")
    ];

    const filteredDomGroup = domGroups.filter(el => {
      return getComputedStyle(el).position !== "fixed";
    });
    if (winWidth <= disableAt) {
      domGroups.forEach(el => (el.style.height = ""));
      return;
    }

    domIgnore.forEach(hide);

    const tallest = tallestEl(filteredDomGroup);

    //const tallest = setTimeout(() => {
    //  tallestEl(filteredDomGroup);
    //}, 50);


    filteredDomGroup.forEach(el => (el.style.height = `${tallest}px`));

    domIgnore.forEach(show);
  });
};

const matchingHeights = () => {
  setTimeout(() => {
    matchingHeightsAction();
  }, 250);
  
  window.addEventListener("resize", didWidthChange);
};

export default matchingHeights;
