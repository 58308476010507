const App__insuranceTable = {
  debug: false,

  init: function() {
    this.setupFilterSpanListeners();
    this.setupMobileFilterListeners();
    this.setupEnterKeyListener();
    this.setupDetailsTabListener();
    this.setEqualHeight();
    window.addEventListener('resize', this.setEqualHeight);
  },

  quickLinksDefaultText: document.querySelector('.insurance-table .quick-links-title')?.getAttribute('data-default-text'),

  setupFilterSpanListeners: function() {
    const filterContainer = document.getElementById('insurance-table__filter--filters');
    if (!filterContainer) {
      return;
    }

    const buttons = filterContainer.querySelectorAll('button');
    buttons.forEach(button => {
      button.addEventListener('click', event => {
        event.preventDefault();
        this.handleFilterClick(buttons, event);
      });
      button.addEventListener('keypress', event => {
        if (event.key === 'Enter') {
          event.preventDefault();
          this.handleFilterClick(buttons, event);
        }
      });
    });
  },

  setupMobileFilterListeners: function() {
    const filterContainer = document.querySelector('.insurance-table__filter--mobile');
    if (!filterContainer) {
      return;
    }

    const links = filterContainer.querySelectorAll('button');
    links.forEach(link => {
      link.addEventListener('click', event => {
        event.preventDefault();
        this.handleFilterClick(links, event);
      });
      link.addEventListener('keypress', event => {
        if (event.key === 'Enter') {
          event.preventDefault();
          this.handleFilterClick(links, event);
        }
      });
    });
  },

  setupEnterKeyListener: function() {
    const detailsElement = document.querySelector('.insurance-table__filter--mobile details');
    if (!detailsElement) {
      return;
    }

    detailsElement.addEventListener('keypress', event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        detailsElement.open = !detailsElement.open;
      }
    });
  },

  setupDetailsTabListener: function() {
    const detailsElement = document.querySelector('.insurance-table__filter--mobile details');
    if (!detailsElement) {
      return;
    }

    const lastButton = detailsElement.querySelector('ul.homepage-icon-routing-list li:last-child button');
    if (!lastButton) {
      return;
    }

    lastButton.addEventListener('keydown', event => {
      if (event.key === 'Tab') {
        detailsElement.removeAttribute('open');
        detailsElement.setAttribute('aria-hidden', 'true');
      }
    });
  },

  handleFilterClick: function(elements, event) {
    elements.forEach(el => el.classList.remove('active'));
    event.target.classList.add('active');

    const plan = event.target.getAttribute('data-plan');
    this.filterTableByPlan(plan);

    this.closeQuickLinks(plan);

    if (this.debug) console.log('Active class toggled:', event.target);
  },

  filterTableByPlan: function(plan) {
    const allTh = document.querySelectorAll('.insurance-table table th');
    const allTd = document.querySelectorAll('.insurance-table table td');

    if (plan === 'all') {
      this.updateDisplayProperties(allTh, true);
      this.updateDisplayProperties(allTd, true);
    } else {
      this.updateDisplayProperties(allTh, false, plan);
      this.updateDisplayProperties(allTd, false, plan);
    }

    if (this.debug) {
      console.log(`Filtered to show ${plan}`);
    }
  },

  updateDisplayProperties: function(elements, showAll, plan = '') {
    elements.forEach(el => {
      if (showAll || el.getAttribute('role') === 'rowheader' || el.getAttribute('data-filter') === plan) {
        el.classList.toggle('filtered', !showAll);
        el.style.display = '';
      } else {
        el.style.display = 'none';
      }
    });
  },

  closeQuickLinks: function(plan = '') {
    const quickLinksTitle = document.querySelector('.insurance-table .quick-links-title');
    if (!quickLinksTitle) return;

    let planText = this.quickLinksDefaultText;

    if (plan !== 'all') {
      planText = document.querySelector(`.insurance-table ul.homepage-icon-routing-list li button[data-plan='${plan}']`)?.innerText;
    }
    quickLinksTitle.innerText = planText;

    const details = document.querySelector('.insurance-table details');

    if (details) {
      details.open = false;
    }
  },

  setEqualHeight: function() {
    const tables = document.querySelectorAll('.insurance-table__table');
    
    tables.forEach(table => {
      const headerRow = table.querySelector('thead tr');
      const ths = headerRow.querySelectorAll('th');
      let maxHeight = 0;

      ths.forEach(th => {
        th.style.height = 'auto';
        const div = th.querySelector('div');
        if (div) {
          div.style.height = 'auto';
        }
      });

      ths.forEach(th => {
        const height = th.offsetHeight;
        if (height > maxHeight) {
          maxHeight = height;
        }
      });

      // ths.forEach(th => {
      //   th.style.height = `${maxHeight}px`;
      //   const div = th.querySelector('div');
      //   if (div) {
      //     div.style.height = '100%'; 
      //   }
      // });
    });
  }
};

export default App__insuranceTable;
