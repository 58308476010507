const photoGridWidget = (() => {

  const init = () => {

    const photoGridWidgetItems = document.querySelectorAll('.photo-grid-widget__item');
    // console.log(photoGridWidgetItems)
    // Make entire card clickable
    photoGridWidgetItems.forEach(function( photoGridWidgetItem ) {

      photoGridWidgetItem.querySelectorAll('a').forEach(function( photoGridWidgetItemLink ) {

        photoGridWidgetItemLink.addEventListener('click', (e) => {
          e.preventDefault();
        });
      });

      photoGridWidgetItem.addEventListener('click', (e) => {
        let link = photoGridWidgetItem.querySelector('a');
        let target = link.getAttribute('target');

        if ( link ) {
          if ( target === '_blank' ) {
            window.open( link );
            return false;
          } else {
            window.location = link;
          }
        }
      });

    });

  };

  init();

})();

export default photoGridWidget;
