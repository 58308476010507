const UpperGlobalAlertBanner = {
  banner: null,
  checkbox: null,
  closeButton: null,
  cookieHash: null,
  isInitialized: false,

  init: function () {
    if (this.isInitialized) return;
    this.banner = document.getElementById("upper-global-alert-banner"); // Update the selector
    this.checkbox = document.getElementById("doNotShowAgain");
    this.closeButton = document.getElementById(
      "close-upper-global-alert-header",
    );

    if (this.banner === undefined || this.banner === null) return;

    const hashElement = document.getElementById("banner-cookie-hash");
    if (hashElement) {
      this.cookieHash = hashElement.getAttribute("hash");
    }

    if (this.getCookie("UpperGlobalAlertHash") === this.cookieHash) {
      this.banner.classList.remove("active");
      return;
    }

    window.addEventListener("load", () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      this.banner.classList.add("active");
    });

    if (this.closeButton && !this.closeButton.hasAttribute("data-listener")) {
      this.closeButton.addEventListener("click", (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.closeBanner();
      });
      this.closeButton.setAttribute("data-listener", "true"); 
    }
  },

  closeBanner: function () {
    this.banner.classList.remove("active");

    if (this.checkbox && this.checkbox.checked) {
      this.setCookie("UpperGlobalAlertHash", this.cookieHash, 365);
    }
  },

  setCookie: function (name, value, days) {
    const d = new Date();
    d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie = `${name}=${encodeURIComponent(value)};${expires};path=/`;
  },

  getCookie: function (name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length);
    }
    return null;
  },
};

export default UpperGlobalAlertBanner;
