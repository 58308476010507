// import classie from "classie";

var getClosest = function(elem, selector) {
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function(s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1;
      };
  }

  // Get closest match
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }

  return null;
};

var getHeight = function(el) {
  var el_style = window.getComputedStyle(el),
    el_display = el_style.display,
    el_position = el_style.position,
    el_visibility = el_style.visibility,
    el_max_height = el_style.maxHeight.replace("px", "").replace("%", ""),
    el_margin =
      parseFloat(el_style["marginTop"]) + parseFloat(el_style["marginBottom"]),
    wanted_height = 0;

  // if its not hidden we just return normal height
  if (el_display !== "none" && el_max_height !== "0") {
    return el.offsetHeight;
  }

  // the element is hidden so:
  // making the el block so we can meassure its height but still be hidden
  // el.style.position   = 'absolute';
  el.style.visibility = "hidden";
  el.style.display = "block";
  el.style.width = "275px";

  wanted_height = parseInt(el_style.height);

  // reverting to the original values
  el.style.display = el_display;
  el.style.position = el_position;
  el.style.visibility = el_visibility;
  el.style.width = "auto";

  if (el.classList.contains("panel-collapse")) {
    el_max_height = el_max_height + 40;
  }

  return wanted_height;
};

var handleEmbeddedYoutube = function(elParent, el) {
  const vid = el.querySelector('iframe[src*="youtube.com"]');
  if (vid && !elParent.classList.contains("active")) {
    const iframe = vid.contentWindow;
    const func = "pauseVideo";
    iframe.postMessage(
      '{"event":"command","func":"' + func + '","args":""}',
      "*"
    );
  }
};

var toggleSlide = function(el, elParent) {
  var el_max_height = 0;
  // var el_padding = 20;
  if (el.getAttribute("data-max-height")) {
    // we've already used this before, so everything is setup
    if (el.style.maxHeight.replace("px", "").replace("%", "") === "0") {
      el.style.maxHeight = el.getAttribute("data-max-height");
      // el.style.paddingBottom = el.getAttribute('data-padding');
      // elParent.classList.toggle("active");

      //    setTimeout(function() {
      //      el.style.maxHeight ="none";
      // }, 400);
    } else {
      el.style.maxHeight = "0";
      // el.style.paddingBottom = '0';
    }
    elParent.classList.toggle("active");
    if (el.getAttribute('aria-hidden') === 'true') {
      el.setAttribute('aria-hidden', false);
    } else {
      el.setAttribute('aria-hidden', true);
    }
    handleEmbeddedYoutube(elParent, el);
  } else {
    el_max_height = getHeight(el) * 2 + "px";

    el.style["transition"] = "all 0.03s ease-in";
    el.style.overflowY = "hidden";
    el.style.maxHeight = "0";
    // el.style.paddingBottom             = '0';
    el.setAttribute("data-max-height", el_max_height);
    // el.setAttribute('data-padding', 30);
    el.style.display = "block";
    elParent.classList.toggle("active");
    if (el.getAttribute('aria-hidden') === 'true') {
      el.setAttribute('aria-hidden', false);
    } else {
      el.setAttribute('aria-hidden', true);
    }
    // we use setTimeout to modify maxHeight later than display (to we have the transition effect)
    setTimeout(function() {
      el.style.maxHeight = el_max_height;
      setTimeout(function() {
        el.style.maxHeight = "none";
      }, 400);
    }, 10);
  }
};

const extractText = node => {
  const nodes = Array.from(node.childNodes).filter(f => f.nodeName === "#text");
  return nodes.length ? nodes[0].textContent.trim() : "";
};

const debounce = function(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

const extend = function(a, b) {
  for (var key in b) {
    if (b.hasOwnProperty(key)) {
      a[key] = b[key];
    }
  }
  return a;
};

// taken from https://github.com/inuyaksa/jquery.nicescroll/blob/master/jquery.nicescroll.js
const hasParent = function(e, id) {
  if (!e) return false;
  var el = e.target || e.srcElement || e || false;
  while (el && el.id != id) {
    el = el.parentNode || false;
  }
  return el !== false;
};

// const getLevelDepth = function(e, id, waypoint, cnt) {
//   cnt = cnt || 0;
//   if (e.id.indexOf(id) >= 0) return cnt;
//   if (classie.has(e, waypoint)) {
//     ++cnt;
//   }
//   return e.parentNode && getLevelDepth(e.parentNode, id, waypoint, cnt);
// };

// http://coveroverflow.com/a/11381730/989439
var mobilecheck = function() {
  var check = false;
  (function(a) {
    if (
      /(android|ipad|playbook|silk|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

// returns the closest element to 'e' that has class "classname"
// const closest = function(e, classname) {
//   if (classie.has(e, classname)) {
//     return e;
//   }
//   return e.parentNode && closest(e.parentNode, classname);
// };

const getParents = function(elem, selector) {
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function(s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1;
      };
  }

  // Set up a parent array
  var parents = [];

  // Push each parent element to the array
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (selector) {
      if (elem.matches(selector)) {
        parents.push(elem);
      }
      continue;
    }
    parents.push(elem);
  }

  // Return our parent array
  return parents;
};

const cssProps = () =>
  window.CSS && window.CSS.supports && window.CSS.supports("(--foo: red)");

const cssObjectFit = () => "objectFit" in document.documentElement.style;

const cssObjectFitContain = () => {
  const container = document.querySelectorAll(".object-fit-contain");
  [...container].forEach(el => {
    el.dataset.objectFitActive = "true";
    const image = el.querySelector("img");

    if (image) {
      let imageSrc = image.getAttribute("src");
      if (
        image.hasAttribute("data-src") &&
        image.getAttribute("data-src").length > 0
      ) {
        imageSrc = image.getAttribute("data-src");
      }
      image.style.opacity = "0";
      el.style.backgroundImage = "url(" + imageSrc + ")";
    }
  });
};

const cssObjectFitCover = () => {
  const container = document.querySelectorAll(".object-fit-cover");
  [...container].forEach(el => {
    el.dataset.objectFitActive = "true";
    const image = el.querySelector("img");
    if (image) {
      let imageSrc = image.getAttribute("src");
      if (
        image.hasAttribute("data-src") &&
        image.getAttribute("data-src").length > 0
      ) {
        imageSrc = image.getAttribute("data-src");
      }
      image.style.opacity = "0";
      el.style.backgroundImage = "url(" + imageSrc + ")";
    }
  });
};

function getElementContentWidthHeight(element, dimension) {
  var styles = window.getComputedStyle(element);

  var padding = 0;
  if (dimension === "width") {
    padding = parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight);
  }

  return element.getBoundingClientRect()[dimension] - padding;
}

const clearFileInput = function(ctrl) {
  try {
    ctrl.value = null;
  } catch (ex) {}
  if (ctrl.value) {
    ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
  }
  let parent = getClosest(ctrl, ".is-dirty");
  if (parent) {
    parent.classList.remove("is-dirty");
  }
};

const iOS = (() => {
  var iDevices = [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod"
  ];

  if (!!navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()) {
        return true;
      }
    }
  }
  return false;
})();

const urlBase64ToUint8Array = base64String => {
  var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const clientBrowser = (() => {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) != -1
  ) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Chrome") != -1) {
    return "Chrome";
  } else if (
    navigator.userAgent.indexOf("Safari") != -1 &&
    navigator.userAgent.indexOf("CriOS") != -1
  ) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") != -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    return "Firefox";
  } else if (
    navigator.userAgent.indexOf("MSIE") != -1 ||
    !!document.documentMode == true
  ) {
    //IF IE > 10
    return "IE";
  } else {
    return "unknown";
  }
})();

const isRunningStandalone = (() => {
  return window.matchMedia("(display-mode: standalone)").matches;
})();

export {
  getElementContentWidthHeight,
  cssProps,
  getClosest,
  toggleSlide,
  getHeight,
  extractText,
  debounce,
  extend,
  hasParent,
  //getLevelDepth,
  mobilecheck,
  // closest,
  getParents,
  cssObjectFit,
  cssObjectFitContain,
  cssObjectFitCover,
  clearFileInput,
  iOS,
  urlBase64ToUint8Array,
  clientBrowser,
  isRunningStandalone
};
