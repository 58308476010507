const App__headshotBioGridWidget = {

  init: function() {

    const headshotBioGridWidgetItems = document.querySelectorAll('.headshot-bio-grid-widget__item');
    // Make entire card clickable
    headshotBioGridWidgetItems.forEach(function( headshotBioGridWidgetItem ) {

      headshotBioGridWidgetItem.querySelectorAll('a').forEach(function( headshotBioGridWidgetItemLink ) {

        headshotBioGridWidgetItemLink.addEventListener('click', (e) => {
          e.preventDefault();
        });
      });

      headshotBioGridWidgetItem.addEventListener('click', (e) => {
        let link = headshotBioGridWidgetItem.querySelector('a');
        let target = link.getAttribute('target');

        if ( link ) {
          if ( target === '_blank' ) {
            window.open( link );
            return false;
          } else {
            window.location = link;
          }
        }
      });

    });

  }

}

export default App__headshotBioGridWidget;
