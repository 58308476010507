function insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

function removeElement(id) {
    var elem = document.getElementById(id);
    if(elem) {
        elem.parentNode.removeChild(elem);
    }   
}


const fileUploadSize = () => {
    const fileUploadFields = document.querySelectorAll('.c-upload input[type=file]');
    const maxFilesize = 4194304; //4MB
    if(fileUploadFields) {
        //for(let uploadField of fileUploadFields) {
        document.addEventListener('change', (event) => {
            if (event.target.matches('.c-upload input[type=file]'))
            {
                var uploadField = event.target;
                var numFiles = uploadField.files.length;
                var files = uploadField.files;
                var fileSize = 0;
                var fieldID = uploadField.id;

                var newAlertId = "filesize-error-" + fieldID;
        
                if (numFiles > 0) {
                    for (var i = 0; i < numFiles; i++) {
                        fileSize = fileSize+files[i].size; // get file size
                    }
        
                    if(fileSize > maxFilesize) {
                        //alert('File size must not be more than 4 MB');
                        removeElement(newAlertId);
                        //Reset input field
                        uploadField.classList.add('filesize-validation-error');
                        uploadField.value = '';

                        //Create error and insert in DOM
                        var newAlert = document.createElement("p");
                        newAlert.classList.add("c-form__error");
                        newAlert.setAttribute("role", "alert");
                        newAlert.setAttribute("id", newAlertId);
                        newAlert.setAttribute("aria-invalid", "true");
                        var msg = document.createTextNode("Total file size limit of 4MB exceeded.");
                        newAlert.appendChild(msg);
                        insertAfter(newAlert, uploadField);
                    } else {
                        removeElement(newAlertId);
                        uploadField.classList.remove('filesize-validation-error');
                    }
                } else {
                    removeElement(newAlertId);
                }
            }
        });
    }
}

export default fileUploadSize;