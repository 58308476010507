const App__capacity = {
  debug: true,
  triggers: [],

  init: function() {
    this.triggers = Array.from(
      document.querySelectorAll("button[data-capacity-status]")
    );

    if (this.triggers.length === 0) return;

    if (this.debug) console.log({ capacity: this.triggers });

    this.listenToTriggers();
    this.listenToEscape();
  },

  listenToTriggers: function() {
    this.triggers.forEach(trigger => {
      trigger.addEventListener("click", e => {
        this.toggleDialog(trigger, "click");
      });

      trigger.addEventListener("mouseenter", e => {
        this.openDialog(trigger);
      });

      trigger.addEventListener("mouseleave", e => {
        this.closeDialog(trigger, "mouseleave");
      });
    });
  },

  listenToEscape: function() {
    document.addEventListener("keydown", function(e) {
      if (e.key !== "Escape" || e.keyCode !== 27) return;

      App__capacity.triggers.forEach(trigger => {
		App__capacity.closeDialog(trigger, "click");
      });
    });
  },

  getTriggerAndDialog: function(trigger) {
    const expanded = trigger.getAttribute("aria-expanded");
    const clicked = trigger.getAttribute("data-clicked");
    const dialogID = trigger.getAttribute("aria-controls");
    const dialog = document.getElementById(dialogID);

    return {
      expanded: expanded === "true",
      clicked: clicked === "true",
      dialog: dialog
    };
  },

  toggleDialog: function(trigger, event) {
    const { expanded, dialog, clicked } = this.getTriggerAndDialog(trigger);

    if (expanded && clicked) {
      this.closeDialog(trigger, event);
      return;
    }

    this.openDialog(trigger);

    if (event === "click") {
      trigger.setAttribute("data-clicked", true);
    }
  },

  closeDialog: function(trigger, event = "mouseout") {
    const { expanded, dialog, clicked } = this.getTriggerAndDialog(trigger);

    if (clicked && event !== "click") return;

    dialog.close();
    trigger.setAttribute("aria-expanded", false);
    trigger.removeAttribute("data-clicked");
  },

  openDialog: function(trigger, event) {
    const { expanded, dialog } = this.getTriggerAndDialog(trigger);
    dialog.show();
    trigger.setAttribute("aria-expanded", true);
  }
};

export default App__capacity;
