const socialWidget = (() => {

  const init = () => {

    const socialWidgetItems = document.querySelectorAll('.social-widget__card');
    // console.log(socialWidgetItems)
    // Make entire card clickable
    socialWidgetItems.forEach(function( socialWidgetItem ) {

      socialWidgetItem.querySelectorAll('a').forEach(function( socialWidgetItemLink ) {

        socialWidgetItemLink.addEventListener('click', (e) => {
          e.preventDefault();
        });
      });

      socialWidgetItem.addEventListener('click', (e) => {
        let link = socialWidgetItem.querySelector('a');
        let target = link.getAttribute('target');

        if ( link ) {
          if ( target === '_blank' ) {
            window.open( link );
            return false;
          } else {
            window.location = link;
          }
        }
      });

    });

  };

  init();

})();

export default socialWidget;
