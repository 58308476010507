const strCompare = (target, comp) => {
  let index = -1;
  for (var i = 0; i < comp.length; i++) {
    if (comp[i] === target[i]) {
      index = i;
    } else {
      break;
    }
  }
  return index;
};

const createMap = (original, before, after) => {
  const indexOA = strCompare(original, after);
  const indexOB = strCompare(original, before);
  const p1 =
    indexOA >= 0 ? original.slice(indexOA, original.length + 1) : original;
  const p2 = p1 === original ? "" : original.slice(0, indexOA);
  const p3 =
    indexOB >= 0 ? original.slice(indexOB, original.length + 1) : original;
  const p4 = p3 === original ? "" : original.slice(0, indexOB);
  return [p1, p2, p3, p4];
};

const animatedText = () => {
  const dynamic = document.querySelectorAll(".c-animated-text-display");
  if (!dynamic.length) {
    return;
  }
  dynamic.forEach(element => {
    const strings = JSON.parse(element.dataset.strings);

    if (!Array.isArray(strings)) {
      return;
    }

    if (strings.length === 1) {
      element.textContent = strings[0];
      element.style.animation = "none";
      return;
    }

    if (strings.length === 0) {
      return;
    }

    // console.log(strings);
    // let orderedPhrases = strings.map((phrase, i)=>{
    // const prev = strings[i-1] || strings[strings.length-1];
    // console.log(element, " : ",prev);
    // const next = strings[i+1] || strings[0];
    const orderedPhrases = strings.map((phrase, i) => {
      const prev = strings[i - 1] || strings[strings.length - 1];
      const next = strings[i + 1] || strings[0];
      return createMap(phrase, prev, next);
    });

    // const prevSlice = strCompare(phrase, prev);
    //  console.log(prevSlice);
    // const nextSlice = strCompare(phrase, next);
    // arr.push(phrase);
    // let prevString = prevSlice>=0?phrase.slice(0, prevSlice):phrase.slice(prevSlice, phrase.length-1);
    // let nextString = nextSlice>=0?phrase.slice(0, nextSlice):"";
    // if (nextString.length){
    //   prevString= phrase.slice(phrase.indexOf(nextString), phrase.length-1);
    // }

    // const arr= [phrase, nextString, prevString, ];
    //  console.log(arr);
    //   return arr;
    // });

    //   console.log(orderedPhrases);

    //   orderedPhrases=[
    //     ["doctor", "find a ",  "find a doctor",""],
    //     ["find a location", "", "location", "find a "],
    //     ["search database", "", "search database", ""]

    //   ];

    const common = Object.assign(document.createElement("span"), {
      className: "c-animated-text-common"
    });
    element.parentNode.insertBefore(common, element);

    // console.log(ds);
    let cnt = 0;
    // const strings = JSON.parse(element.dataset.strings);
    element.addEventListener("animationend", e => {
      const parentWidth = `${
        element.parentNode.getBoundingClientRect().width
      }px`;

      const elOffset = `${common.offsetLeft}px`;
      if (
        e.animationName === "text-fade-in" ||
        e.animationName === "ie-text-fade-in"
      ) {

        element.textContent = orderedPhrases[cnt][2];
        common.textContent = orderedPhrases[cnt][3];
        element.parentNode.style.paddingLeft = orderedPhrases[cnt][3].length
          ? elOffset
          : "";
        element.parentNode.style.textAlign = orderedPhrases[cnt][3].length
          ? "left"
          : "center";

        // element.parentNode.style.paddingLeft = `${
        //   element.parentNode.getBoundingClientRect().width
        // }px`;

        element.parentNode.classList.add("c-animated-text__fade-out");
      } else {
        element.textContent = orderedPhrases[cnt][0];
        common.textContent = orderedPhrases[cnt][1];
        // element.parentNode.style.width = "";
        element.parentNode.classList.remove("c-animated-text__fade-out");
        if (cnt === strings.length - 1) {
          cnt = 0;
        } else {
          cnt++;
        }
      }
      // element.textContent=strings[cnt];

      element.style.animation = "none";
      element.offsetHeight;
      element.style.animation = null;
      element.style.animation = "";
    });
  });
};

export default animatedText;
