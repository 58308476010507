//Fix a11y on recaptcha
const App__addA11Recaptcha = {
  textAreaCheck: [],
  init: function () {
    App__addA11Recaptcha.textAreaCheck = document.getElementsByClassName(
      "g-recaptcha-response",
    );
    if (App__addA11Recaptcha.textAreaCheck.length === 0) return;

    if (document.readyState === "ready" || document.readyState === "complete") {
      App__addA11Recaptcha.handleRecaptcha();
    } else {
      document.onreadystatechange = function () {
        if (document.readyState == "complete") {
          App__addA11Recaptcha.handleRecaptcha();
        }
      };
    }
  },

  handleRecaptcha: function () {
    //set label on recaptcha response
    App__addA11Recaptcha.textAreaCheck.forEach((item) => {
      item.setAttribute("aria-hidden", true);
      item.setAttribute("aria-labelledby", "g-recaptcha-response");
    });
  },
};

export default App__addA11Recaptcha;
