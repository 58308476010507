const scrollToTop = elm => {
  const locationNav = document.querySelector(
    ".banner-location-detail-hub-anchor-nav"
  );

  const elementTop = elm.getBoundingClientRect().top;
  const windowScroll = window.scrollY;

  let locationNavHeight = 0;
  if (locationNav !== undefined && locationNav !== null) {
    locationNavHeight = locationNav.getBoundingClientRect().height;
  }

  const scrollToField = Math.floor(
    elementTop + windowScroll - locationNavHeight
  );

  window.scrollTo({
    top: scrollToField
  });
};

const listenToScrollToErrorField = () => {
  const forms = [
    ...document.querySelectorAll('.c-form[data-ro-submit="true"]')
  ];

  const locationNav = document.querySelector(
    ".banner-location-detail-hub-anchor-nav"
  );

  if (forms === undefined || forms.length === 0) return;

  document.addEventListener("click", e => {
    const errorGroup = e.target.closest(".c-form__error-group");

    // Not triggered from a scroll to error link.
    if (errorGroup === undefined || errorGroup === null) return;

    if (e.target.localName !== "a") return;

    e.preventDefault();

    const id = e.target.hash.replace("#", "");
    const field = document.getElementById(id);

    if (field === undefined || field === null) return;

    const label = field.previousElementSibling;

    // Measurements
    const fieldTop = field.getBoundingClientRect().top;
    const windowScroll = window.scrollY;
    const buffer = 5;

    let locationNavHeight = 0;
    if (locationNav !== undefined && locationNav !== null) {
      locationNavHeight = locationNav.getBoundingClientRect().height;
    }

    let labelHeight = 16;
    if (label !== null && label.localName === "label") {
      labelHeight = label.getBoundingClientRect().height;
    }

    const scrollToField = Math.floor(
      fieldTop + windowScroll - locationNavHeight - labelHeight - buffer
    );

    window.scrollTo({
      top: scrollToField
    });

    field.focus();
  });
};

listenToScrollToErrorField();

function createErrorSummary(errors, errorTitle, errorText) {
  const parentDiv = document.createElement("div");
  parentDiv.classList.add("c-form__error-group");
  parentDiv.setAttribute("tabindex", "0");
  parentDiv.setAttribute("aria-atomic", "true");
  parentDiv.setAttribute("aria-live", "assertive");

  const h3 = document.createElement("h3");
  h3.innerText = errorTitle;
  parentDiv.appendChild(h3);

  const errorSummaryText = document.createElement("p");
  errorSummaryText.innerText = errorText;
  parentDiv.appendChild(errorSummaryText);

  const listDiv = document.createElement("div");
  listDiv.classList.add("c-numbered-list");
  listDiv.style.marginBottom = "1.5rem";
  parentDiv.appendChild(listDiv);

  const orderedList = document.createElement("ol");
  listDiv.appendChild(orderedList);

  for (const error of errors) {
    const inputField = error.id.replace("error-field-form-", "form-");
    const listItem = document.createElement("li");
    const anchor = document.createElement("a");
    anchor.setAttribute("href", `#${inputField}`);
    anchor.innerText = error.innerText;

    listItem.appendChild(anchor);
    orderedList.appendChild(listItem);
  }

  return parentDiv;
}

let recaptchaChecked = false;

const recaptchaVerifiedCallback = response => {
  if (!response) return;

  recaptchaChecked = true;
};

const recaptchaExpiredCallback = () => {
  setTimeout(() => {
    grecaptcha.reset();
    recaptchaChecked = false;
  }, 2500);
};

const renderRecaptcha = () => {
  let captcha = document.querySelector(".g-recaptcha");

  if (captcha === undefined || captcha === null) return;

  if (!grecaptcha || grecaptcha === undefined || grecaptcha === null) return;

  grecaptcha.ready(function() {
    recaptchaChecked = false;

    setInterval(() => {
      if (!recaptchaChecked) {
        grecaptcha.reset();
      }
    }, 2 * 60 * 1000);

    grecaptcha.render(captcha, {
      sitekey: captcha.dataset.sitekey,
      callback: recaptchaVerifiedCallback,
      "expired-callback": recaptchaExpiredCallback
    });
  });
};

const FormSubmit = (() => {
  const JSONTryParse = data => {
    try {
      JSON.parse(data);
    } catch (e) {
      return false;
    }
    return true;
  };

  const removeAttributes = elm => {
    elm.removeAttribute("onsubmit");
    elm.removeAttribute("data-ktc-ajax-update");
  };

  const handleSubmit = e => {
    const form = e.target;
    if (!form.hasAttribute("data-ro-submit")) {
      return;
    }

    let formTag = document.getElementById(form.id);
    let container = formTag.parentNode;

    e.preventDefault();
    e.stopImmediatePropagation();

    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);

    xhr.onreadystatechange = function() {
      if (xhr.readyState == 4 && xhr.status == 200) {
        const data = xhr.response;

        // REDIRECT
        if (JSONTryParse(data)) {
          const jsonObject = JSON.parse(data);
          if (jsonObject.redirectTo) {
            location.href = jsonObject.redirectTo;
          }
          return;
        }

        // DISPLAY TEXT
        if (data.includes("formwidget-submit-text")) {
          container.innerHTML = data;
          return;
        }

        let parser = new DOMParser();
        var dataDoc = parser.parseFromString(data, "text/html");
        const dataFormTag = dataDoc.getElementById(form.id);

        // FORM SUBMITTED - AFTER ACTION IS TO RELOAD FORM
        if (!dataFormTag) {
          const form = dataDoc.querySelector("form");
          container.innerHTML = form?.parentNode?.innerHTML;
          window.removeEventListener("submit", handleSubmit);
          FormSubmit.init();
          return;
        }

        // VALIDATION ERRORS
        removeAttributes(dataFormTag);
        const errors = [...dataFormTag.querySelectorAll("p.c-form__error")];
        const existingErrorDiv = [
          ...container.querySelectorAll(".c-form__error-group")
        ];

        for (const div of existingErrorDiv) {
          div.remove();
        }

        var errorTitle = form.getAttribute("data-error-group-title");
        var errorText = form.getAttribute("data-error-group-text");
        var errorSummaryDiv = createErrorSummary(errors, errorTitle, errorText);

        if (errorSummaryDiv !== null) {
          dataFormTag.insertBefore(errorSummaryDiv, dataFormTag.firstChild);
          formTag.classList.add("has-errors");
        } else {
          formTag.classList.remove("has-errors");
        }

        const firstFocusableLink = container.querySelector(
          '[aria-live="assertive"] a'
        );

        if (firstFocusableLink) {
          firstFocusableLink.focus({ preventScroll: true });
        } else {
          container.focus({ preventScroll: true });
        }

        formTag.innerHTML = dataFormTag.innerHTML;

        renderRecaptcha();

        scrollToTop(formTag);

        return;
      }
    };
    xhr.send(new FormData(form));
  };

  return {
    init: () => {
      renderRecaptcha();

      const forms = [
        ...document.querySelectorAll('.c-form[data-ro-submit="true"]')
      ];

      forms.forEach(formInstance => {
        removeAttributes(formInstance);

        window.addEventListener("submit", e => {
          handleSubmit(e);
        });
      });
    }
  };
})();

export default FormSubmit;
