const anchors = [
  ...document.querySelectorAll(
    `a:not([href^="#"]):not([href^="/"]):not([href^="${window.location.origin}"]):not([href^="${window.location.host}"])`
  )
];

// const srText = Object.assign(document.createElement("div"), {
//   textContent: "Opens in a new tab",
//   className: "a11y-hidden"
// });

// const icon = Object.assign(document.createElement("i"), {
//   className: "fas fa-external-link"
// });

const externalLink = () => {
  anchors.forEach(el => {
    // el.appendChild(srText.cloneNode(true));
    // if (el.querySelectorAll("i").length === 0) {
    //   el.appendChild(icon.cloneNode(true));
    //   el.classList.add("c-external-link");
    // }
    el.target = "_blank";
    el.rel = "noreferrer";
  });
};

export default externalLink;
