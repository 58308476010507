const stickyAnchorNav = (() => {

  const init = () => {
    // Attach event listeners
    const stickyAnchorNav__trigger = document.querySelector('[data-hook=stickyAnchorNav__trigger]');

    if ( stickyAnchorNav__trigger ) {

      const stickyAnchorNav__linkList = stickyAnchorNav__trigger.nextElementSibling;
      stickyAnchorNav__trigger.addEventListener('click', function(e) {
        // e.preventDefault()

        // Open
        if ( stickyAnchorNav__trigger.getAttribute('aria-expanded') == 'false' ) {
          stickyAnchorNav__trigger.innerHTML = 'Close <span class="sr-only">Quick Links</span>';
          stickyAnchorNav__trigger.setAttribute('aria-expanded', 'true');
          // If bottom of title is not in viewport, scroll up a bit to make it obvious that new content has appeared
          let stickyAnchorNav__boundingBox = stickyAnchorNav__trigger.getBoundingClientRect();

          if ( stickyAnchorNav__boundingBox.bottom > (window.innerHeight || document.documentElement.clientHeight) ) {
            window.scrollBy({
              top: 200,
              behavior: 'smooth'
            });
          }
        // Close
        } else {
          stickyAnchorNav__trigger.innerHTML = '<span class="sr-only">Open</span> Quick Links';
          stickyAnchorNav__trigger.setAttribute('aria-expanded', 'false');
        }

      });
    

      if ( stickyAnchorNav__linkList ) {

        const stickyAnchorNav__linkListItems = stickyAnchorNav__linkList.querySelectorAll('li');

        if ( stickyAnchorNav__linkListItems ) {
          stickyAnchorNav__linkListItems.forEach(function( stickyAnchorNav__linkListItem ) {
            stickyAnchorNav__linkListItem.addEventListener('click', function(e) {
              e.preventDefault();
              stickyAnchorNav__trigger.innerHTML = '<span class="sr-only">Open</span> Quick Links';
              stickyAnchorNav__trigger.setAttribute('aria-expanded', 'false');

              let anchorLink = stickyAnchorNav__linkListItem.querySelector('a');
              let anchorLinkHref = anchorLink.getAttribute('href');
              let anchorName = anchorLinkHref.substr(1, anchorLinkHref.length - 1);

              let anchorElement = document.querySelector('[name=' + anchorName + ']');
              let headerOffset = 100;
              let elementPosition = anchorElement.getBoundingClientRect().top;
              let offsetPosition = elementPosition + window.pageYOffset - headerOffset;
              window.scrollTo({
                top: offsetPosition
              });
            });
          });
        }
      }
    }
  }

  init();

})();

export default stickyAnchorNav;
