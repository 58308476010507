// $(function() {
//   $('.btn-6')
//     .on('mouseenter', function(e) {
// 			var parentOffset = $(this).offset(),
//       		relX = e.pageX - parentOffset.left,
//       		relY = e.pageY - parentOffset.top;
// 			$(this).find('span').css({top:relY, left:relX})
//     })
//     .on('mouseout', function(e) {
// 			var parentOffset = $(this).offset(),
//       		relX = e.pageX - parentOffset.left,
//       		relY = e.pageY - parentOffset.top;
//     	$(this).find('span').css({top:relY, left:relX})
//     });
//   $('[href=#]').click(function(){return false});
// });

const elements = [...document.querySelectorAll(".c-direction-aware-overlay")];

const init = () => {
  elements.forEach(el => {
    const span = el.querySelector(".c-direction-aware-overlay-overlay");
    el.addEventListener(
      "mouseenter",
      function(e) {
        const { left, top } = this.getBoundingClientRect();

        const relX = e.clientX - left;
        const relY = e.clientY - top;

        span.style.top = `${relY}px`;
        span.style.left = `${relX}px`;

        // console.log("enter");
        // console.log("page ", e.clientX);
        // console.log("off ", left);
      },
      true
    );
    el.addEventListener("mouseout", function(e) {
      const { left, top } = this.getBoundingClientRect();
      const relX = e.clientX - left;
      const relY = e.clientY - top;

      span.style.top = `${relY}px`;
      span.style.left = `${relX}px`;
    });
  });
};

export default { init };
