// import { debounce } from "./globals";
// import * as focusTrap from "focus-trap";
//import reframe from "reframe.js";

const videoModal = (() => {

  const focusTrap = require('focus-trap');

  const openTranscriptAccordion = function( transcriptAccordion ) {
    let transcriptAccordion__toggleButton = transcriptAccordion.querySelector('.video-modal__transcript-toggle-button');
    let transcriptAccordion__transcriptContent = transcriptAccordion.querySelector('.video-modal__transcript-content');
    transcriptAccordion__toggleButton.setAttribute('aria-expanded', 'true');
    transcriptAccordion__toggleButton.querySelector('.video-modal__transcript-toggle-button > span').textContent = transcriptAccordion__toggleButton.dataset.textHide;
    transcriptAccordion__transcriptContent.setAttribute('aria-hidden', 'false');
    transcriptAccordion__transcriptContent.focus();
    transcriptAccordion.dataset.state = 'open';
  }

  const closeTranscriptAccordion = function( transcriptAccordion ) {
    let transcriptAccordion__toggleButton = transcriptAccordion.querySelector('.video-modal__transcript-toggle-button');
    let transcriptAccordion__transcriptContent = transcriptAccordion.querySelector('.video-modal__transcript-content');
    transcriptAccordion.querySelector('.video-modal__transcript-toggle-button').setAttribute('aria-expanded', 'false')
    transcriptAccordion__toggleButton.querySelector('.video-modal__transcript-toggle-button > span').textContent = transcriptAccordion__toggleButton.dataset.textShow;
    transcriptAccordion__transcriptContent.setAttribute('aria-hidden', 'true');
    transcriptAccordion__toggleButton.focus();
    transcriptAccordion.dataset.state = 'closed';
  }

  const closeModal = function ( modal, trap ) {
    if ( trap ) {
      trap.deactivate();
    }
    if ( modal ) {
      modal.style.display = 'none';
      modal.remove();
    }
    // let modalOverlay = document.querySelector('.video-modal-overlay');
    // if ( modalOverlay ) {
    //   modalOverlay.style.display = 'none';
    //   modalOverlay.remove();
    // }
  }

  const init = () => {

    let homepageBannerContent__playLink = document.querySelector('.homepage-banner-content__play-link');

    if ( homepageBannerContent__playLink ) {      
      document.querySelector('.homepage-banner-content__play-link')?.addEventListener('click', (e) => {
        e.preventDefault();

        if ( document.querySelector('.video-modal') ) {

          let body = document.querySelector('body');

          // Clone modal & inject into top level of DOM
          let modal = document.querySelector('.video-modal').cloneNode(true);

          // Close modal when background overlay is clicked
          modal.querySelector('.video-modal__overlay').addEventListener('click', (e) => {
            e.preventDefault();
            closeModal(modal, trap);
          }, true);

          body.appendChild( modal );

          // reframe('iframe[src*="youtube"], iframe[src*="vimeo"]');

          modal.style.display = 'block';

          // window.scrollTo({ top: 0, behavior: 'smooth' });

          let trap = focusTrap.createFocusTrap( modal );
          trap.activate();

          // Handle close modal button click
          modal.querySelector('.video-modal__close').addEventListener('click', (e) => {
            e.preventDefault();
            closeModal(modal, trap);
          });

          // Handle transcript accordion
          if ( modal.querySelector('.video-modal__transcript') ) {
            let transcriptAccordion = modal.querySelector('.video-modal__transcript');
            let transcriptAccordion__toggleButton = transcriptAccordion.querySelector('.video-modal__transcript-toggle-button');
            transcriptAccordion__toggleButton.addEventListener('click', (e) => {
              e.preventDefault();
              if ( transcriptAccordion.dataset.state == 'open' ) {
                closeTranscriptAccordion( transcriptAccordion );
              } else {
                openTranscriptAccordion( transcriptAccordion );
              }
            });
          }

          // Handle ESC key press to close modal
          window.addEventListener('keydown', (e) => {
            switch (e.key) {
              case "Esc": // IE/Edge specific value
                closeModal(modal, trap);
                break;
              case "Escape":
                closeModal(modal, trap);
                break;
              default:
                return;
            }
            // Cancel the default action to avoid it being handled twice
            e.preventDefault();
          }, true);

        }

      }, false);

    }

  };

  return { init };

})();

export default videoModal;
