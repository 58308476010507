const links = [...document.querySelectorAll(".has-children")];

const linksWithChildren = () => {
  links.forEach(el => {
    const anchor = el.querySelector("a");
    const listEl = anchor.nextSibling;
    anchor.addEventListener("focusin", () => {
      el.classList.add("active");
    });
    anchor.addEventListener("mouseenter", () => {
      el.classList.add("active");
    });
    el.addEventListener("mouseleave", () => {
      el.classList.remove("active");
    });
    el.addEventListener("focusout", () => {
      setTimeout(() => {
        el.contains(document.activeElement) || el.classList.remove("active");
      }, 0);
    });
    const theChildren = [...el.querySelectorAll(".has-children li a")];

    theChildren.forEach(innerEl => {
      innerEl.addEventListener("focusin", () => {
        el.classList.add("active");
      });
      innerEl.addEventListener("focusout", () => {
        setTimeout(() => {
          el.contains(document.activeElement) || el.classList.remove("active");
        }, 0);
      });
    });
  });
};

export default linksWithChildren;
