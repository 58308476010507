function createInnerHTML(item)
{
    var markup = '<div class=\"c-ratings-left\">'
    + '<div class=\"c-ratings-stars\">';
      for (var f = 0; f < item.fullStarCount; f++)
      {
        markup = markup + '<span class=\"c-ratings-star is-full\">'
          + '<i class=\"fa-solid fa-star\"></i>'
        + '</span>';
      }
      for (var h = 0; h < item.halfStarCount; h++)
      {
        markup = markup + '<span class=\"c-ratings-star is-half\">'
          + '<i class=\"fa-solid fa-star-half-stroke\"></i>'
        + '</span>';
      }
      for (var e = 0; e < item.emptyStarCount; e++)
      {
        markup = markup + '<span class=\"c-ratings-star is-empty\">'
          + '<i class=\"fa-regular fa-star\"></i>'
        + '</span>';
      }
    markup = markup + '</div>'
    + '<p>' + item.commentReviewDate + '</p>'
  + '</div>';

  if (item.commentTeaser === null)
  {
    markup = markup + '<p>' + item.commentFullText + '</p>';
  } else {
    markup = markup + '<div class="c-ratings-right">' +
                        '<p data-ratings>' +
                          '<span class="c-ratings-item-teaser" data-ratings-teaser>' + item.commentTeaser +
                            '<span class="c-ratings-item-full" data-ratings-full>' + item.commentMore + '</span>' +
                          '</span>' + '&nbsp' +
                          '<button class="c-button c-button__bare link-action" data-ratings-toggle>' +
                            '<span>' + ' ... ' + item.commentTeaserReadMore + '</span>' +
                          '</button>' +
                        '</p>' +
                      '</div>';
  }


  return markup;
}

function loadMore(url)
{
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          var data = JSON.parse(this.responseText);
          var ul = document.getElementById("patientReviewComments");
          if (ul)
          {
              data.comments.map((item) => {
                  var li = document.createElement("li");
                  li.setAttribute("class", "c-ratings-item c-flex-text")
                  li.innerHTML = createInnerHTML(item);
                  ul.appendChild(li);
            });
            //dom.i2svg();
            showmore();
          }
          const patientReviewMore = document.querySelectorAll('button#patientReviewMore');
          if (patientReviewMore)
          {
            if (!data.hasMore)
            {
                patientReviewMore[0].style.display = 'none'
            }
            else
            {
                patientReviewMore[0].style.display = 'block';
            }
          }
        }
     };
    xhttp.open("GET", url, true);
    xhttp.send();
}

const doctordetail = () => {
  
  showmore();

  const patientReviewMore = document.querySelectorAll('button#patientReviewMore');
  if (patientReviewMore.length > 0) {
      patientReviewMore[0].addEventListener('click', (event) => {
          var pagenum = patientReviewMore[0].getAttribute('data-pagenum');
          var url = patientReviewMore[0].getAttribute('data-api');
          loadMore(url + "&pagenum=" + pagenum);
          patientReviewMore[0].setAttribute('data-pagenum', parseInt(pagenum)+1);
      });
  }
}

const showmore = () => {
  const patientComments = Array.from(document.querySelectorAll('[data-ratings]'));

  if (patientComments.length > 0) {

    patientComments.forEach(comment => {

      const toggle = comment.querySelector('[data-ratings-toggle]');
      const teaser = comment.querySelector('[data-ratings-teaser]');
      
      if (!toggle.classList.contains("is-hidden")) {
        const full = comment.querySelector('[data-ratings-full]');
        if (toggle) {
          const inner = teaser.innerText.trim();
          const inner2 = full.textContent.trim();

          toggle.addEventListener('click', (e) => {
            e.preventDefault();
            teaser.innerHTML = inner + inner2;
            toggle.classList.add('is-hidden');
            full.classList.add('is-visible');
            //full.tabIndex = 0;
            //full.focus();
          });
        }
      }
    });
  }
}



export default doctordetail;
