import { clientBrowser } from "./globals";

const els = Array.from(document.querySelectorAll('[data-browser-detect]'));
var ieWarningTag = document.querySelectorAll('c-banner');

const browserDetect = (() => {

	return {
		init: () => {
			if (els.length > 0 && clientBrowser === 'IE') {
				els.forEach(el => {
					el.classList.add('is-warning');
					el.innerHTML = '<span>Warning</span> ' + el.getAttribute('data-ie-warning-text');
				});
			}
		}
	}

})();

export default browserDetect;
