import { debounce, getClosest, toggleSlide } from "./globals";
// import blazy from "./blazy";

const accordion = (() => {
  const panel = document.querySelectorAll(".accordion-panel button");
  const handleSR = el => {
    const hasSR = el.querySelectorAll(".sr-item");
    if (hasSR.length > 0) {
      [...hasSR].forEach(item => {
        item.style.opacity = "";
        item.style.visibility = "visible";
      });
    }
  };

  const handleBlazy = el => {
    const blazyImages = el.querySelectorAll(".b-lazy");
    if (blazyImages.length > 0) {
      [...blazyImages].forEach(image => {
        if (!image.classList.contains("b-loaded")) {
          blazy.bLazy.load(image);
        }
      });
    }
  };

  const handleButtonForTranscript = (buttonEl,el) => {
    if (buttonEl.getAttribute('aria-expanded') === 'true') {
      buttonEl.setAttribute('aria-expanded', false);
    } else {
      buttonEl.setAttribute('aria-expanded', true);
      const transcriptEl = el.querySelector(".c-video__transcript");
      transcriptEl.focus();
    }
  }

  const doSomething = function(e) {
    e.preventDefault();
    const closestElem = getClosest(this, ".accordion-panel");
    const el = closestElem.querySelector(".panel-collapse");
    const buttonEl = closestElem.querySelector(".collapsed");
    const blazyImages = el.querySelectorAll(".b-lazy");
    const hasSR = el.querySelectorAll(".sr-item");
    const elParent = this.parentNode;
    toggleSlide(el, elParent);
    handleBlazy(el);
    handleSR(el);
    if (buttonEl)
    {
      handleButtonForTranscript(buttonEl, el);
    }
  };

  const defaultOpen = (elem, closeIt) => {
    const closestElem = getClosest(elem, ".accordion-panel");
    const el = closestElem.querySelector(".panel-collapse");
    const elParent = elem.parentNode;
    const active = closestElem.querySelector(".active");
    if (window.innerWidth > 600 && !active) {
      toggleSlide(el, elParent);
      handleBlazy(el);
      handleSR(el);
    } else if (window.innerWidth <= 600 && active) {
      toggleSlide(el, elParent);
    }
  };

  let winWidth = window.innerWidth;
  const thisIsMobile = debounce(() => {
    if (winWidth !== window.innerWidth) {
      winWidth = window.innerWidth;
      [].map.call(panel, elem => {
        if (elem.classList.contains("only-mobile")) {
          defaultOpen(elem, true);
        }
      });
    }
  }, 100);

  const init = () => {
    thisIsMobile();
    window.addEventListener("resize", thisIsMobile);
    const hash = window.location.hash && window.location.hash.substring(1);
    [].map.call(panel, elem => {
      if (
        elem.classList.contains("only-mobile") ||
        elem.classList.contains("eligibility") ||
        (elem.id === hash && hash)
      ) {
        defaultOpen(elem);
      }
      elem.addEventListener("click", doSomething, false);
    });
    return false;
  };

  init();
})();

export default accordion;
