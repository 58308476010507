/**
 * Handled filtering groups when a pill (radio button) is clicked.
 *
 * @typedef {Object} App__filterPills Uses pills (radio buttons) to filter groups.
 * @property {HTMLInputElement[]} App__filterPills.pills The array of pills
 * @property {HTMLDivElement[]} App__filterPills.groups The array of groups
 * @property {boolean[]} App__filterPills.debug Used to send debug information to the console.
 * @property {HTMLButtonElement} App__filterPills.reset The button used to reset the filters.
 * @property {HTMLButtonElement} App__filterPills.dropdown The button used to toggle the filters on mobile
 */
const App__filterPills = {
  debug: false,

  reset: null,
  dropdown: null,

  pills: [],
  groups: [],

  /**
   * Initialze the component.
   *
   * @returns void
   */
  init: function () {
    this.pills = document.querySelectorAll("input[name=filterPillsInput]");

    this.groups = document.querySelectorAll(".filter-pills__cards");

    this.reset = document.querySelector(".filter-pills__reset");

    this.dropdown = document.querySelector(".filter-pills__dropdown");

    if (this.debug) console.log(`pills`, this.pills, `groups`, this.groups);

    if (this.pills.length === 0 || this.groups.length === 0) return;

    this.listenToPillsChange();
    this.listenToReset();
    this.listenToDropdown();

    this.handleReset();
  },

  /**
   * Bind an event listener to the dropdown button for mobile.
   *
   * @returns void
   */
  listenToDropdown: function () {
    this.dropdown.addEventListener("click", (e) => {
      const button = e.target;
      const pillList = document.getElementById(
        button.getAttribute("aria-controls"),
      );

      if (this.debug) {
        console.log(pillList);
        console.log(
          button.getAttribute("aria-controls"),
          button.getAttribute("aria-expanded"),
        );
      }

      if (this.dropdown.getAttribute("aria-expanded") == "true") {
        pillList.removeAttribute("data-open");
        button.setAttribute("aria-expanded", false);
        return;
      }

      pillList.setAttribute("data-open", "");
      button.setAttribute("aria-expanded", true);
    });
  },

  /**
   * Bind an event listener to the reset button
   *
   * @returns void
   */
  listenToReset: function () {
    this.reset.addEventListener("click", () => {
      this.handleReset();
    });
  },

  /**
   * Bind eventslisteners to all of the fills.
   *
   * @uses handleFilterGroup
   *
   * @returns void
   */
  listenToPillsChange: function () {
    this.pills.forEach((pill) => {
      pill.addEventListener("change", (e) => {
        if (this.debug) console.log(e);
        this.handleFilterGroup(e.target);
      });
    });
  },

  /**
   * Handle the actual filtering.
   *
   * @uses hideGroups
   * @uses showGroup
   * @uses showReset
   *
   * @param {HTMLInputElement} selectedPill
   */
  handleFilterGroup: function (selectedPill) {
    const matchingGroup = Array.from(this.groups).filter((group) =>
      group.dataset.filters.includes(selectedPill.id),
    );

    if (this.debug) console.log({ matchingGroup, pill: selectedPill.id });

    this.hideGroups();

    this.showGroup(matchingGroup);

    this.showReset();

    this.handleScroll(matchingGroup);
  },

  /**
   * Hides the groups passed in from the user.
   *
   * @uses groups
   *
   * @param {HTMLDivElement[]} groups
   */
  hideGroups: function (groups = null) {
    if (groups === null) {
      groups = this.groups;
    }

    groups.forEach((group) => {
      group.setAttribute("aria-hidden", true);
    });
  },

  /**
   * Shows the group passed the to user
   *
   * @uses groups
   *
   * @param {HTMLDivElement[]} groups
   */
  showGroup: function (groups = null) {
    if (groups === null) {
      groups = this.groups;
    }

    groups.forEach((group) => {
      group.setAttribute("aria-hidden", false);
    });
  },

  /**
   * Hides the reset button from the user.
   *
   * @uses reset
   */
  hideReset: function () {
    this.reset.setAttribute("aria-hidden", true);
  },

  /**
   * Shows the reset button to the user.
   *
   * @uses reset
   */
  showReset: function () {
    this.reset.setAttribute("aria-hidden", false);
  },

  /**
   * Resets all of the groups and pills back to show state.
   *
   * @uses hideGroups
   * @uses showGroup
   * @uses hideReset
   */
  handleReset: function () {
    const allGroup = Array.from(this.groups).filter((group) =>
      group.dataset.filters.includes("all"),
    );

    this.hideGroups();
    this.showGroup(allGroup);
    this.pills.forEach((pill) => (pill.checked = false));
    this.hideReset();
  },

  /**
   * Handle scrolling the page down to the first item in the matching group. 
   * 
   * @param {HTMLDivElement[]} matchingGroup 
   * @returns void
   */
  handleScroll: function (matchingGroup) {

    if (matchingGroup.length === 0) return;
    
    // Scroll to the first active group with aria-hidden="false"
    const firstVisibleGroup = matchingGroup.find(
      (group) => group.getAttribute("aria-hidden") === "false",
    );

    if (firstVisibleGroup) {
      firstVisibleGroup.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  },
};

export default App__filterPills;
