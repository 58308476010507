const buttons = [...document.querySelectorAll(".stickyButton")];
const parentEl = document.querySelector('.c-drop-down-cta.c-drop-down-cta__left.sticky');

const scrollFunction = button => {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    // button.style.display = "block";
    button.classList.add("stickyShow");
    // console.log(button);
  } else {
    // button.style.display = "none";
    button.classList.remove("stickyShow");
  }
};

const scrollToTop = () => {
  // const c = document.documentElement.scrollTop || document.body.scrollTop;
  // if (c > 0) {
  //   window.requestAnimationFrame(scrollToTop);
  //   window.scrollTo(0, c - c / 10);
  // }

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth"
  });
};

const closeSticky = () => {
  parentEl.classList.add('is-hidden');
};

const backToTop = () => {
  buttons.forEach(button => {
    window.addEventListener("scroll", () => {
      scrollFunction(button);
    });
    if (button.id === "backToTop") {
      button.addEventListener("click", scrollToTop);
    }
    if (button.id === "backToTop2") {
      button.addEventListener("click", scrollToTop);
    }
  });

  const close = document.querySelector('.stickyClose');

  if (close) {
    close.addEventListener('click', closeSticky);
  }
};
export default backToTop;
