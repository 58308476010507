const countersArr = [...document.querySelectorAll(".c-counter__item")];

// const getRandomInt = (min, max) => {
//   min = Math.ceil(min);
//   max = Math.floor(max);
//   return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
// };

let options = {
  // root: document.querySelector("body"),
  // rootMargin: "0px",
  threshold: 1.0
};

const runCounterAnimation = el => {
  const counterNumber = parseInt(el.dataset.counterNumber);
  const elementToChange = el.querySelector(".c-counter__item-number");
  const randomNumbers = [counterNumber];

  let spliter = counterNumber / 20;

  for (let i = 0; i < 19; i++) {
    let rand =
      parseInt(randomNumbers[0] - spliter) <= 0
        ? 0
        : randomNumbers[0] - spliter;
    randomNumbers.unshift(rand);
  }
  const cleanedRandomNumbers = randomNumbers.map(i => parseInt(i));
  const animation = setInterval(() => {
    elementToChange.textContent = cleanedRandomNumbers.shift();
    if (!cleanedRandomNumbers.length) {
      clearInterval(animation);
      el.classList.add("c-counter__item-animationend");
    }
  }, 50);
};

const callback = e => {
  e.forEach(el => {
    if (el.isIntersecting) {
      runCounterAnimation(el.target);
      observer.unobserve(el.target);
    }
  });
};

let observer = new IntersectionObserver(callback, options);

const counter = () => {
  countersArr.forEach(el => observer.observe(el));
};

export default counter;
