//import theme from "../../theme/";
import theme from "../theme/";

const dynamicList = (() => {
  const lists = [
    ...document.querySelectorAll("ul[data-dynamicListInitialQty]")
  ];

  const button = textContent =>
    Object.assign(document.createElement("button"), {
      innerHTML: `<span>${textContent}</span>`,
      className: "c-button c-button__bare link-action hide-on-print"
    });

  // const icon = i =>
  //   Object.assign(document.createElement("i"), {
  //     className: `fa fa-${i}`
  //   });

  const getAlign = align =>
    ({ left: "flex-start", center: "center", right: "flex-end" }[align]);

  const buttonContainer = align =>
    Object.assign(document.createElement("div"), {
      className: `has-margin__top flex flex__${
        align === "center" ? "center" : "left"
      }`
    });

  const handleClick = items => {
    // const ul = items[0].parentNode;
    // ul.classList.add('green-border');
    // setTimeout(()=>{
    //   ul.classList.remove('green-border');
    // },1000)
    items.forEach((item, i) => {
      // item.style.display = "list-item";
      item.style.display = "block";
      setTimeout(() => {
        item.style.visibility = "visible";
        item.style.maxHeight = `${item.dataset.max}px`;
        item.style.opacity = "1";
        if (i == 0) {
          setTimeout(() => {
            if (items[0]) {
              items[0].tabIndex = "0";
              items[0].focus({ focusVisible: true });
              items[0].style.outline = "none";
            }
          }, 5);
        }
      }, 5);
    });
  };

  const getMaxHeight = item => {
    item.style.maxWidth = "250px";
    const height = item.clientHeight * 3;
    item.style.maxWidth = "";
    return height;
  };

  return {
    init: () => {
      lists.forEach(list => {
        const {
          dynamiclistinitialqty: qty,
          dynamiclistinitialqtymobile: mobileQty,
          dynamiclistbtntext: textContent,
          dynamiclistalign: align = "center"
        } = list.dataset;
        let trigger = button(textContent);

        trigger.style.color = theme.color.uiPrimary;
        // const iconDef = findIconDefinition({
        //   prefix: "far",
        //   iconName: "angle-down"
        // });

        //const iconEl = icon(iconDef);
        const iconEl = document.createElement("i");
        iconEl.classList.add("fa-regular", "fa-angle-down");
        //Array.from(iconEl.node).map(n => trigger.prepend(n));
        trigger.prepend(iconEl);

        const listItems = [...list.querySelectorAll("li")].filter(item =>
          // The filter is to ensure we're not inadvertantly collapsing list items that are children of the top level hidden items.
          item.parentElement.hasAttribute("data-dynamicListInitialQty")
        );
        
        let hiddenItems;
        if (
          window.innerWidth <= 600 &&
          mobileQty !== undefined &&
          mobileQty !== null
        ) {
          hiddenItems = listItems.slice(mobileQty);
        } else {
          hiddenItems = listItems.slice(qty);
        }

        if (!hiddenItems.length) {
          return;
        }
        hiddenItems.forEach((item, i) => {
          item.setAttribute("data-max", getMaxHeight(item));
          item.style.maxHeight = "0";
          item.style.visibility = "hidden";
          item.style.opacity = "0";
          item.style.oveflow = "hidden";
          item.style.display = "none";

          item.style.transition = `max-height 200ms ease-out, opacity 1000ms ${i *
            30}ms ease`;
        });

        trigger.addEventListener("click", function fn1(e) {
          e.preventDefault();
          handleClick(hiddenItems);
          trigger.removeEventListener("click", fn1);
          trigger.parentNode.classList.add("fade-out-up");

          hiddenItems[hiddenItems.length - 1].addEventListener(
            "transitionend",
            function fn2() {
              const pn = trigger.parentNode;

              pn.parentNode.removeChild(pn);
              hiddenItems[hiddenItems.length - 1].removeEventListener(
                "transitionend",
                fn2
              );
            }
          );
        });
        const buttons = buttonContainer(getAlign(align));
        buttons.appendChild(trigger);
        list.parentNode.appendChild(buttons);
      });
    }
  };
})();

export default dynamicList;
