const gridItems = () => {


    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    function getLastGridItem(parentElement) {
        const childItems = parentElement.getElementsByClassName('o-grid-item');
        let lastItem = null;
        let revealed = false;
            for (let i = 0; i < childItems.length; i++) {
                if (window.getComputedStyle(childItems[i]).display === "none" && revealed == false) {
                    lastItem = childItems[i];
                    revealed = true;
                }
            }
        return lastItem;
    }

    //check if we have grid items
    var gridItemsExist = !!document.getElementsByClassName("c-grid-view__items");
    if(gridItemsExist) {

        //Hide the flash of unstyled chevron
        window.onload = function () {

            var chevrons = document.getElementsByClassName("grid-chevron");
            
            for (let i = 0; i < chevrons.length; i++) {
                chevrons[i].style.display = "inline";
            }
        };



        

        var showMoreLinks = document.getElementsByClassName("grid-show-more");

        for (let i = 0; i < showMoreLinks.length; i++) {
            showMoreLinks[i].addEventListener('click', (e) => {

                let parentGrid = showMoreLinks[i].parentNode.parentNode;
                
                //console.log("Show more link: ", showMoreLinks[i]);

                let focusItem = getLastGridItem(parentGrid);

                showMoreLinks[i].classList.add("hide-link");
                parentGrid.classList.add("show-all-items");
                focusItem.focus();
            });
        }
    }
    
}

export default gridItems;