const App__comparisonTable = {
  // Debugger, in browser console type App.comparisonTable.debug = true or set this to true in dev mode
  debug: false, 

  tables: null,
  dialogTriggers: null,

  /**
   * Initialize method for comparison tables
   * @sets this.tables
   * @sets this.dialogTriggers
   * @uses this.listenToIntersection
   * @uses this.listenToDialogTriggers
   * @uses this.listenToKeyboard
   * @returns void
   */
  init: function() {
    this.tables = document.querySelectorAll(
      "[data-hook=comparisonTable] table thead"
    );

    if (this.debug) console.log({ tables: this.tables });

    if (
      this.tables === undefined ||
      this.tables === null ||
      this.tables.length === 0
    )
      return;

    this.listenToIntersection();

    this.dialogTriggers = document.querySelectorAll(
      "button[data-hook=comparisonTable__dialogTrigger]"
    );

    if (this.debug) console.log({ dialogTriggers: this.dialogTriggers });

    if (
      this.dialogTriggers === undefined ||
      this.dialogTriggers === null ||
      this.dialogTriggers.length === 0
    )
      return;

    this.listenToDialogTriggers();

    this.listenToKeyboard();

    this.showFirstDialog();
  },

  /**
   * This method is looking for when the table head becomes sticky.
   * There is some trickery going on here to ensure we don't find ourselves in a position where the data-sticky get's applied and removed in rapid succession.
   * - Setting css top to -1px: This ensures that the head intersects the viewport.  If you set it to 0, it never truly intersects and it's nearly impossible to detect.
   * - Debounce: to ensure it doesn't fire the change more than once per stated time.
   * - Height of the head:  This height changed dynamically based on whether it's stuck or not, so we check when the method fires to have the most accurate information
   * - Buffer: because the size of the head changes we provide a buffer of pixels to ensure the smoothest possible experience.
   *
   * @uses this.tables
   * @uses this.activeTable
   * @uses window.IntersectionObserver
   * @returns void
   */
  listenToIntersection: function() {
    let isDebouncing = false;
    const debounce = 250; // in ms
    const buffer = 15; // in px
    const observerOptions = {
      trackVisibility: true,
      delay: 100,
      threshold: [1]
    };

    /**
     * Callback for our observer.
     * @returns void
     */
    const handleIntersection = entries => {
      if (this.debug) console.log({ entries });

      entries.forEach(entry => {
        // Will settle at -1 when fully stuck
        const entryTopRelativeToViewport = entry.boundingClientRect.y;
        const entryHeight = entry.boundingClientRect.height;

        // The threshold in-which we consider the element to be "stuck"
        const stuckThreshold = Math.floor(entryHeight + buffer);

        // Only apply stuck when the entries position from the top is under the threshold we set.
        // We're not looking for -1, because it'll technically not reach it if we adjust the height with css, that's one of the causes the jittering effect.
        const scrolledWithinThreshold =
          entryTopRelativeToViewport < stuckThreshold;

        // Head is stuck to the top if the intersector is less than one and
        if (
          entry.intersectionRatio < 1 &&
          scrolledWithinThreshold &&
          !isDebouncing
        ) {
          isDebouncing = true;
          entry.target.setAttribute("data-sticky", "");

          // We're not stuck, but don't change it if we're in a debounce period.
        } else if (!isDebouncing) {
          isDebouncing = true;
          entry.target.removeAttribute("data-sticky");
        }

        // Reset the debounce.
        setTimeout(() => {
          isDebouncing = false;
        }, debounce);

        if (this.debug)
          console.log({
            entry,
            debounce,
            buffer,
            entryTopRelativeToViewport,
            entryHeight,
            stuckThreshold,
            scrolledWithinThreshold,
            isDebouncing
          });
      });
    };

    // Setup a new observer.
    const observer = new window.IntersectionObserver(
      handleIntersection,
      observerOptions
    );

    // Observe all tables that may be on the page.
    this.tables.forEach(table => {
      if (this.debug) console.log("observing:", table);

      observer.observe(table);
    });
  },

  /**
   *
   * Listens to button clicks and toggles the table head descriptions on mobile.
   *
   * @uses this.dialogTriggers
   * @uses this.hideDialog
   * @uses this.showDialog
   * @returns void
   */
  listenToDialogTriggers: function() {
    /**
     * Toggles the table head descriptions on mobile.
     * @param {Event} e
     * @returns void
     */
    const handleEvent = e => {
      const target = e.currentTarget;

      if (this.debug) console.log({ e });

      if (target === undefined || target === null) return;

      this.dialogTriggers.forEach(trigger => {
        // Close all dialogs that are not the one we just clicked on.
        if (!trigger.isEqualNode(target)) {
          this.hideDialog(trigger);
        }
      });

      // If the one we clicked on is already open it, close it.
      if (target.hasAttribute("data-open")) {
        this.hideDialog(target);
        return;
      }

      // Otherwise show it.
      this.showDialog(target);
    };

    // Setup Listeners
    this.dialogTriggers.forEach(trigger => {
      trigger.addEventListener("click", handleEvent);
    });
  },

  showFirstDialog: function () {

    const firstTrigger = this.dialogTriggers[0];
    this.showDialog(firstTrigger);
    
  },

  /**
   *
   * When escape is pressed it'll hide any open dialogs.
   * @uses this.dialogTriggers
   * @returns void
   *
   */
  listenToKeyboard: function() {
    if (this.debug) console.log("listenToKeyboard");
    document.addEventListener("keydown", e => {
      if (e.key !== "Escape" && e.key !== "Esc") return;

      this.dialogTriggers.forEach(trigger => {
        this.hideDialog(trigger);
      });
    });
  },

  /**
   * Sets the trigger and the dialog to the appropriate states to hide.
   * @param {HTMLButtonElement} trigger
   * @returns void
   */
  hideDialog: function(trigger) {
    const dialog = trigger.parentNode.querySelector(
      ".comparison-table__dialog"
    );

    if (this.debug) console.log({ trigger, dialog, open: false });

    if (dialog === undefined || dialog === null) return;

    trigger.removeAttribute("data-open");
    trigger.setAttribute("aria-expanded", false);

    dialog.removeAttribute("data-open");
    dialog.setAttribute("aria-hidden", true);

    this.tables.forEach(table => {
      table.parentNode.removeAttribute('data-dialog-open');
    })
  },

  /**
   * Sets the trigger and the dialog to the appropriate states to show.
   * @param {HTMLButtonElement} trigger
   * @returns void
   */
  showDialog: function(trigger) {
    const dialog = trigger.parentNode.querySelector(
      ".comparison-table__dialog"
    );

    if (this.debug) console.log({ trigger, dialog, open: true });

    if (dialog === undefined || dialog === null) return;

    trigger.setAttribute("data-open", "");
    trigger.setAttribute("aria-expanded", true);

    dialog.setAttribute("data-open", "");
    dialog.setAttribute("aria-hidden", false);

    this.tables.forEach(table => {
      table.parentNode.setAttribute('data-dialog-open', '');
    })
  }
};

export default App__comparisonTable;
