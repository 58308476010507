import "../scss/style.scss";

import dynamicList from "./components/dynamicList";
import counter from "./components/counter";
import detailsSummary from "./components/deatilsSummary";
import animatedText from "./components/animatedText";

import matchingHeights from "./components/matchinHeights";
import objecFit from "./components/objectFit";
import accordion from "./components/accordion";
import accordionWidget from "./components/accordionWidget";
import externalLink from "./components/externalLink";
import formSubmit from "./components/formSubmit";
import backToTop from "./components/backToTop";
import linksWithChildren from "./components/linksWithChildren";
import navItemsWithMegamenu from "./components/navItemsWithMegamenu";
import navItemsWithChildButtonBar from "./components/navItemsWithChildButtonBar";
import directionAwareOverlay from "./components/directionAwareOverlay";
import { toggleVisibility } from "./components/toggle-visibility";
import gridItems from "./components/gridItems";
import fileUploadSize from "./components/fileUploadSize";
import doctordetail from "./components/doctordetail";
import browserDetect from "./components/browserDetect";
import latestNewsRouting from "./components/latestNewsRouting";
import videoModal from "./components/videoModal";
import photoGridWidget from "./components/photoGridWidget";
import socialWidget from "./components/socialWidget";
import App__tabsWidget from "./components/tabsWidget";
import App__headshotBioGridWidget from "./components/headshotBioGridWidget";
import App__stickyAnchorNav from "./components/stickyAnchorNav";
import App__comparisonTable from "./components/comparisonTable";
import App__insuranceTable from "./components/insuranceTable";
import App__capacity from "./components/capacity";
import App__tooltip from "./components/tooltip";
import App__filterPills from "./components/filterPills";
import UpperGlobalAlertBanner from "./components/upperGlobalAlertBanner";
import App__addA11Recaptcha from './components/addA11yRecaptcha';
/*
 * Setup the global App object
 * Currrently just used for tabs, but maybe we move more into this as needed
 * =========================================================================== */
let App = (window.App = {});
App.tabsWidget = App__tabsWidget;
App.headshotBioGridWidget = App__headshotBioGridWidget;
App.comparisonTable = App__comparisonTable;
App.insuranceTable = App__insuranceTable;
App.capacity = App__capacity;
App.tooltip = App__tooltip;
App.filterPills = App__filterPills;
App.addA11yRecaptcha = App__addA11Recaptcha;
App.UpperGlobalAlertBanner = UpperGlobalAlertBanner;

(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty("prepend")) {
      return;
    }
    Object.defineProperty(item, "prepend", {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function prepend() {
        var argArr = Array.prototype.slice.call(arguments),
          docFrag = document.createDocumentFragment();

        argArr.forEach(function (argItem) {
          var isNode = argItem instanceof Node;
          docFrag.appendChild(
            isNode ? argItem : document.createTextNode(String(argItem)),
          );
        });

        this.insertBefore(docFrag, this.firstChild);
      },
    });
  });
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);

document.addEventListener("DOMContentLoaded", (e) => {
  (function () {
    typeof document.body.style.grid === "string" ||
      document.body.classList.add("no-grid-support");
  })();

  if (window.top != window.self) {
    document.body.classList.add("cms-view");
  }

  
  formSubmit.init();
  dynamicList.init();
  detailsSummary.init();
  externalLink();
  matchingHeights();
  objecFit();
  animatedText();
  backToTop();
  directionAwareOverlay.init();
  linksWithChildren();
  navItemsWithMegamenu();
  navItemsWithChildButtonBar();
  counter();
  toggleVisibility.init();
  gridItems();
  fileUploadSize();
  doctordetail();
  browserDetect.init();
  videoModal.init();
  App.tabsWidget.init();
  App.headshotBioGridWidget.init();
  App.comparisonTable.init();
  App.insuranceTable.init();
  App.capacity.init();
  App.tooltip.init();
  App.filterPills.init();
  App.addA11yRecaptcha.init();
  App.UpperGlobalAlertBanner.init();
});