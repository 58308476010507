const navItems = [...document.querySelectorAll(".has-megamenu")];

const navItemsWithMegamenu = () => {
  navItems.forEach(navItem => {
    const button = navItem.querySelector("button");
    // const listEl = anchor.nextSibling;

    button.addEventListener("focusin", () => {
      navItem.classList.add("active");
      button.setAttribute('aria-expanded', 'true');
    });

    button.addEventListener("mouseenter", () => {
      navItem.classList.add("active");
      button.setAttribute('aria-expanded', 'true');
    });

    navItem.addEventListener("focusout", () => {
      setTimeout(() => {
        navItem.contains(document.activeElement) || navItem.classList.remove("active");
        button.setAttribute('aria-expanded', 'false');
      }, 0);
    });

    navItem.addEventListener("mouseleave", () => {
      navItem.classList.remove("active");
      button.setAttribute('aria-expanded', 'false');
    });


    // const megamenuLinks = [...navItem.querySelectorAll(".has-megamenu li a")];

    // megamenuLinks.forEach(megamenuLink => {
    //   megamenuLink.addEventListener("focusin", () => {
    //     navItem.classList.add("active");
    //   });

    //   megamenuLink.addEventListener("focusout", () => {
    //     setTimeout(() => {
    //       navItem.contains(document.activeElement) || navItem.classList.remove("active");
    //     }, 0);
    //   });

    // });

  });
};

export default navItemsWithMegamenu;
