// import { debounce, getClosest, toggleSlide } from "./globals";
// import blazy from "./blazy";

const latestNewsRouting = (() => {

  // const handleBlazy = el => {
  //   const blazyImages = el.querySelectorAll(".b-lazy");
  //   if (blazyImages.length > 0) {
  //     [...blazyImages].forEach(image => {
  //       if (!image.classList.contains("b-loaded")) {
  //         blazy.bLazy.load(image);
  //       }
  //     });
  //   }
  // };

  const init = () => {

    const routingCards = document.querySelectorAll(".c-latest-news-routing__card");
    if (routingCards.length > 0) {
      [...routingCards].forEach(routingCardItem => {
        routingCardItem.addEventListener('click', (e) => {
          let link = routingCardItem.querySelector('a');
          let target = link.getAttribute('target');

          if ( link ) {
            if ( target === '_blank' ) {
              window.open( link );
              return false;
            } else {
              window.location = link;
            }
          }
        });
      });
    }

    return false;
  };

  init();
})();

export default latestNewsRouting;
