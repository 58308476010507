const cssObjectFitSupported = "objectFit" in document.documentElement.style;

const container = document.querySelectorAll(".c-object-fit");

const supported = () => {
  [...container].forEach(el => {
    const image = el.querySelector("img");

    image && image.classList.remove("c-object-fit__hidden-on-load");
  });
};

const unSupported = () => {
  [...container].forEach(el => {
    const image = el.querySelector("img");
    if (image) {
      let imageSrc = image.getAttribute("src");
      if (
        image.hasAttribute("data-src") &&
        image.getAttribute("data-src").length > 0
      ) {
        imageSrc = image.getAttribute("data-src");
      }
      el.style.backgroundImage = "url(" + imageSrc + ")";

      if (image.classList.contains("c-object-fit__cover")) {
        el.style.backgroundSize = "cover";
      } else if (image.classList.contains("c-object-fit__contain")) {
        el.style.backgroundSize = "contain";
      }
      image.style.opacity = "0";
      // image.classList.remove("c-object-fit__hidden-on-load");
    }
  });
};

const objectFit = () => {
  cssObjectFitSupported ? supported() : unSupported();
};

export default objectFit;
