const App__tooltip = {
  parents: [],

  init: function() {
    this.parents = Array.from(
      document.querySelectorAll("[data-hook=tooltip]")
    );

    if (this.parents.length === 0) return;

    this.listenToTooltipParents();
  },

  listenToTooltipParents: function() {
    this.parents.forEach(parent => {
      parent.addEventListener("focus", e => {
        this.show(parent);
      });
      parent.addEventListener("blur", e => {
        this.hide(parent);
      });
      parent.addEventListener("mouseenter", e => {
        this.show(parent);
      });
      parent.addEventListener("mouseleave", e => {
        this.hide(parent);
      });
      parent.addEventListener("click", e => {
        this.show(parent);
      });
      document.addEventListener('keydown', e => {
        if (e.key === "Escape" || e.code === "Escape" || e.keyCode === 27) {
          this.hide(parent);
        }
      });
    });
  },
  getTooltip: function(parent) {
    const id = parent.getAttribute("aria-controls");

    const tooltip = document.getElementById(id);

    if (tooltip === undefined || tooltip === null) return;

    return tooltip;
  },
  show: function(parent) {
    const tooltip = this.getTooltip(parent);

    if (!tooltip) return;

    tooltip.setAttribute("aria-hidden", false);
    parent.setAttribute("aria-expanded", true);
  },
  hide: function(parent) {
    const tooltip = this.getTooltip(parent);

    if (!tooltip) return;

    tooltip.setAttribute("aria-hidden", true);
    parent.setAttribute("aria-expanded", false);
  }
};

export default App__tooltip;
