const toggleVisibility = {};

toggleVisibility.toggles = Array.from(document.querySelectorAll('[data-toggleVisibility]'));

toggleVisibility.show = (toggle, el) => {
	toggle.setAttribute('aria-expanded', true);
	el.setAttribute('aria-hidden', false);
};

toggleVisibility.hide = (toggle, el) => {
	toggle.setAttribute('aria-expanded', false);
	el.setAttribute('aria-hidden', true);
};

toggleVisibility.init = () => {
	if (toggleVisibility.toggles.length > 0) {
		toggleVisibility.toggles.forEach(toggle => {
			const el = document.getElementById(toggle.getAttribute('aria-controls'));

			//if (toggle.dataset.toggleVisibility === 'closeOnFocusOut') {
			//	const focusableLinks = Array.from(el.querySelectorAll('a, button, input'));

			//	focusableLinks.forEach(link => {
			//		link.addEventListener('focusout', (e) => {
			//			if (!el.contains(e.relatedTarget)) {
			//				toggleVisibility.hide(toggle, el);
			//			}
			//		});
			//	});
			//}

			toggle.addEventListener('click', (e) => {
				e.preventDefault();
				const expanded = toggle.getAttribute('aria-expanded');
				if (expanded == 'true') {
					toggleVisibility.hide(toggle, el);
				} else {
					toggleVisibility.show(toggle, el);
				}
			});
		});
	}
};

export { toggleVisibility };
