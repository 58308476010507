const accordionwidget = (() => {

  const init = () => {
    // Attach event listeners
    const accordionWidgetTriggers = document.querySelectorAll('.accordion-widget__item-trigger');

    accordionWidgetTriggers.forEach ( function( accordionWidgetTrigger ) {

      accordionWidgetTrigger.addEventListener('click', function(e) {
        e.preventDefault()

        // Open Accordion
        if ( accordionWidgetTrigger.getAttribute('aria-expanded') == 'false' ) {
          accordionWidgetTrigger.setAttribute('aria-expanded', 'true');
          // If bottom of title is not in viewport, scroll up a bit to make it obvious that new content has appeared
          let accordionGroupItemTitleBoundingBox = accordionWidgetTrigger.getBoundingClientRect();
          if ( accordionGroupItemTitleBoundingBox.bottom > (window.innerHeight || document.documentElement.clientHeight) ) {
            window.scrollBy({
              top: 200,
              behavior: 'smooth'
            });
          }
        // Close Accordion
        } else {
          accordionWidgetTrigger.setAttribute('aria-expanded', 'false');
        }

      });

    });

  };

  init();

})();

export default accordionwidget;
