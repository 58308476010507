const App__tabsWidget = {

  init: function() {

    // Tab nav toggler (for small screens)
    const tabsWidget__tabNavTogglers = document.querySelectorAll('.tabs-widget__tab-nav-toggler');
    tabsWidget__tabNavTogglers.forEach( function( tabsWidget__tabNavToggler ) {
      tabsWidget__tabNavToggler.addEventListener('click', (e) => {
        e.preventDefault();
        let tabsWidget__tabNavWrapper = tabsWidget__tabNavToggler.closest(".tabs-widget__tab-nav-wrapper");
        if ( tabsWidget__tabNavWrapper.classList.contains('is-open') ) {
          tabsWidget__tabNavWrapper.classList.remove('is-open');
        } else {
          tabsWidget__tabNavWrapper.classList.add('is-open');
        }
        // tabsWidget__tabNavToggler.blur();
        // console.log(tabsWidget__tabNavWrapper.querySelector('[aria-selected="true"]'));
        // tabsWidget__tabNavWrapper.querySelector('[aria-selected="true"]').focus();
      });
    });

    // Tab triggers
    const tabsWidget__triggers = document.querySelectorAll('.tabs-widget__tab-trigger');
    tabsWidget__triggers.forEach( function( tabsWidget__trigger ) {

      // Add keyboard listener
      tabsWidget__trigger.addEventListener('keydown', (e) => {
        App__tabsWidget.handleKeyboardNav( e, tabsWidget__trigger );
      });

      tabsWidget__trigger.addEventListener('click', (e) => {
        e.preventDefault();

        // Get sibling tab triggers
        let siblings = App__tabsWidget.getTriggerSiblings( tabsWidget__trigger );

        siblings.forEach( function( trigger ) {
          // Deactivate sibling buttons & hide related panels
          trigger.setAttribute('aria-selected', 'false');
          trigger.setAttribute('tabindex', '-1');
          document.querySelector('#' + trigger.getAttribute('aria-controls')).setAttribute('aria-hidden', 'true');
        });

        // Activate clicked button & show related panel
        tabsWidget__trigger.setAttribute('aria-selected', 'true');
        tabsWidget__trigger.removeAttribute('tabindex');
        let tabPanel = document.querySelector('#' + tabsWidget__trigger.getAttribute('aria-controls'));
        tabPanel.setAttribute('aria-hidden', 'false');
        // tabPanel.focus();

        let tabsWidget__tabNavWrapper = tabsWidget__trigger.closest(".tabs-widget__tab-nav-wrapper");
        // triggered from small screen view
        if ( tabsWidget__tabNavWrapper.classList.contains('is-open') ) {
          tabsWidget__tabNavWrapper.classList.remove('is-open');
          // Focus on tab trigger button
          tabsWidget__trigger.focus();
          tabsWidget__tabNavWrapper.querySelector('.tabs-widget__tab-nav-toggler').blur();
        // Triggered from large screen
        } else {
          // Focus on menu button
          tabsWidget__trigger.blur();
          tabsWidget__tabNavWrapper.querySelector('.tabs-widget__tab-nav-toggler').focus();
        }

      });

    });

  },


  getTriggerSiblings: function( tabsWidget__trigger ) {
    let siblings = [];
    if ( tabsWidget__trigger.parentNode ) {
      let sibling = tabsWidget__trigger.parentNode.firstChild;
      while ( sibling ) {
        if ( sibling.nodeType === 1 && sibling !== tabsWidget__trigger ) {
            siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
      }
    }
    return siblings;
  },


  handleKeyboardNav: function ( e, tabsWidget__trigger ) {
    // Bail if not on a tab trigger.
    if (!e.target.classList.contains("tabs-widget__tab-trigger")) return;

    let siblings = App__tabsWidget.getTriggerSiblings( tabsWidget__trigger );
    let numberOfTabs = siblings.length;

    const currentIndex = parseInt(e.target.dataset.index);
    let newIndex = parseInt(currentIndex);
    let flagged = false; // This will flip if one of the keys we've designated it pressed.
    // console.log(currentIndex + ', ' + newIndex);

    switch (e.key) {
      // RIGHT or DOWN
      case "Right": // IE/Edge specific value
      case "ArrowRight":
      case "Down": // IE/Edge specific value
      case "ArrowDown":
        flagged = true;
        // At the end go back to the beginning or increment.
        newIndex = numberOfTabs === currentIndex ? 0 : newIndex + 1;
        break;

      // LEFT or UP
      case "Left": // IE/Edge specific value
      case "ArrowLeft":
      case "Up": // IE/Edge specific value
      case "ArrowUp":
        flagged = true;
        // At the beginning go to the end or decrement.
        newIndex = currentIndex === 0 ? numberOfTabs : newIndex - 1;
        break;

      // HOME or ESC
      case "Home":
      case "Esc": // IE/Edge specific value
        // Do something for "enter" or "return" key press.
        flagged = true;
        newIndex = 0;
        break;

      // END
      case "End":
        // Do something for "esc" key press.
        flagged = true;
        newIndex = numberOfTabs;
        break;

      // NOT THE CORRECT KEY
      default:
        return;
    }

    // A key we're listening to has been pressed.
    if ( flagged ) {
      e.preventDefault();
      e.stopPropagation();
    }

    const parent = e.target.parentNode;
    const selectedSibling = parent.querySelector(`[data-index="${newIndex}"]`);

    if (selectedSibling === undefined) return;

    // this.handleTabState(selectedSibling);
    selectedSibling.focus({focusVisible:true});
  }

}

export default App__tabsWidget;
